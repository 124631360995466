<template>
  <div class="upload-area">
    <el-button type="primary" size="mini" @click="showUploadPanel">{{btnName}}</el-button>
    <!-- 弹窗 -->
    <el-dialog class="upload-dialog-area" title="文件管理器" :visible.sync="uploadVisible" width="720px" :before-close="handleClose" v-if="uploadVisible" :append-to-body="true">
      <div class="dialog-content">
        <el-tabs tab-position="left" style="height: 500px;" v-model="activeTab">
          <el-tab-pane label="选择文件" name="selectFile">
            <div class="file-list">
              <div :class="['file-item', {checked: item.checked}]" v-for="(item, index) in imgList" :index="index" :key="index" @click="addFile(item, $event)">
                <el-image style="width: 100px; height: 100px" :src="item.url" fit="cover" v-if="item.att_type === 'jpg' || item.att_type === 'png' || item.att_type === 'gif' || item.att_type === 'JPG' || item.att_type === 'PNG' || item.att_type === 'GIF'"></el-image>
                <el-image style="width: 100px; height: 100px" :src="require('../../assets/img/zip.png')" fit="cover" v-else-if="item.att_type === 'zip'"></el-image>
                <el-image style="width: 100px; height: 100px" :src="require('../../assets/img/doc.png')" fit="cover" v-else-if="item.att_type === 'doc'"></el-image>
                <el-image style="width: 100px; height: 100px" :src="require('../../assets/img/txt.png')" fit="cover" v-else-if="item.att_type === 'txt'"></el-image>
                <el-image style="width: 100px; height: 100px" :src="require('../../assets/img/pdf.png')" fit="cover" v-else-if="item.att_type === 'pdf'"></el-image>
                <el-image style="width: 100px; height: 100px" :src="require('../../assets/img/xls.png')" fit="cover" v-else-if="item.att_type === 'xls'"></el-image>
                <el-image style="width: 100px; height: 100px" :src="require('../../assets/img/video.png')" fit="cover" v-else-if="item.att_type === 'mp4'"></el-image>
                <el-image style="width: 100px; height: 100px" :src="require('../../assets/img/music.png')" fit="cover" v-else-if="item.att_type === 'mp3'"></el-image>
                <span class="selected"><span class="icon el-icon-check"></span></span>
              </div>
            </div>
            <div class="file-pagination">
              <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="15" layout="total, prev, pager, next" :total="fileListtotal"></el-pagination>
            </div>
            <div class="dialog-file-footer">
              <el-button @click="activeTab = 'uploadFile'">上传文件</el-button>
              <el-badge :value="checkedFiles.length" class="item">
                <el-button type="primary" @click="confirmFiles">确 定</el-button>
              </el-badge>
            </div>
          </el-tab-pane>
          <el-tab-pane label="上传文件" name="uploadFile">
            <div class="upload-pane">
              <div class="upload-pane-title">请选择本地文件上传</div>
              <el-upload
                action
                list-type="picture-card"
                ref="upload"
                :on-change="handFileChange"
                :http-request="uploadFiles"
                :auto-upload="false"
                :multiple="true"
                :on-success="uploadFileSuccess"
                :on-remove="handleFileRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
              <div class="upload-pane-tips">仅支持上传 「jpg,png,gif,zip,doc,txt,pdf,xls,mp4,mp3」 文件大小不能超过 2M</div>
              <div class="dialog-upload-footer">
                <el-button @click="activeTab = 'selectFile'">选择文件</el-button>
                <el-badge :value="selectNum" class="item">
                  <el-button type="primary" @click="submitUpload">确定上传</el-button>
                </el-badge>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Upload',
  props: {
    btnName: {
      type: String,
      default: '选择文件'
    },
    // 单文件
    url: String,
    // 多文件
    urlArr: Array,
    filed: String,
    maxNum: Number
  },
  data () {
    return {
      uploadVisible: false,
      imgList: [],
      // 当前文件列表页码
      currentPage: 1,
      // 获取到的后端所有文件数量
      fileListtotal: 0,
      // 激活的tab标签
      activeTab: 'selectFile',
      // 已选择待上传文件数量
      selectNum: 0,
      // 已成功上传的文件数量
      uploadSuccessCount: 0,
      // 已选择文件数组
      checkedFiles: []
    }
  },
  created () {
  },
  methods: {
    // 开启上传选择文件面板
    showUploadPanel () {
      this.uploadVisible = true
    },
    // 自定义上传方法，使用全局axios
    uploadFiles (fileObj) {
      const formData = new FormData()
      formData.set('file', fileObj.file)
      this.$http.post(this.$http.defaults.baseURL + '/upload', formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        },
        // 上传进度
        onUploadProgress: (progressEvent) => {
          const num = progressEvent.loaded / progressEvent.total * 100 | 0 // 百分比
          fileObj.onProgress({ percent: num }) // 进度条
        }
      }).then((response) => {
        fileObj.onSuccess(response)
      }).catch()
    },
    handleClose (done) {
      done()
    },
    async getFileList () {
      const { data: res } = await this.$http.get('/uploaded-list', { params: { currentPage: this.currentPage } })
      if (res.status === 200) {
        this.imgList = res.data.data
        this.currentPage = res.data.current_page
        this.fileListtotal = res.data.total
        // 把已存在checkedFiles中的文件在打上 checked标记
        if (this.maxNum === 1) {
          this.imgList.forEach(i => {
            if (i.url === this.url) {
              i.checked = true
            }
          })
        } else if (this.maxNum > 1) {
          this.imgList.filter((val) => {
            this.urlArr.forEach(i => {
              if (i === val.url) {
                val.checked = true
              }
            })
          })
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getFileList()
    },
    handFileChange (files, fileList) {
      this.selectNum = fileList.length
    },
    handleFileRemove (file, fileList) {
      this.selectNum = fileList.length
    },
    // 上传按钮
    submitUpload () {
      this.$refs.upload.submit()
    },
    // 文件上传成功时的钩子
    uploadFileSuccess (response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error('上传失败')
        return false
      } else {
        this.$message.success('上传成功')
        this.uploadSuccessCount++
        if (fileList.length === this.uploadSuccessCount) {
          this.$refs.upload.clearFiles()
          this.uploadSuccessCount = 0
          this.getFileList()
        }
      }
    },
    // 点击文件，添加文件选中逻辑
    addFile (file, ele) {
      // 检查选择的文件是否已在已选择列表中
      const check = this.checkedFiles.find(element => element === file)
      // 不在已选择列表(如果是多选，则不限制已选择数组的长度)
      if (!check) {
        // 单文件处理
        if (this.maxNum === 1) {
          // 判断已选择数组的长度，超出1则替换
          if (this.checkedFiles.length === 0) {
            ele.target.parentNode.parentNode.classList.add('checked')
            // 添加数据到已选择数组
            this.checkedFiles.push(file.url)
          } else {
            if (document.querySelectorAll('.checked').length > 0) {
              // 1. 清除之前已选择元素的checked样式
              document.querySelectorAll('.checked')[0].classList.remove('checked')
            }
            // 2.先将原来元素从已选择列表删除
            this.checkedFiles = []
            // 3. 给新点击的文件添加样式和加入到已选择数组
            ele.target.parentNode.parentNode.classList.add('checked')
            // 添加数据到已选择数组
            this.checkedFiles.push(file.url)
          }
        } else {
          // 多文件处理
          // 判断已选择数组的长度，超出最大选择数量则提示
          if (this.checkedFiles.length >= this.maxNum) {
            this.$message.error('最多可选择' + this.maxNum + '个文件')
          } else {
            // 1. 给新点击的文件添加样式和加入到已选择数组
            ele.target.parentNode.parentNode.classList.add('checked')
            // 2.添加数据到已选择数组
            this.checkedFiles.push(file.url)
          }
        }
      } else {
        const checkedIndex = this.checkedFiles.find(checkedElement => checkedElement === file)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.checkedFiles.splice(checkedIndex, 1)
        ele.target.parentNode.classList.remove('checked')
      }
    },
    // 确认选择的文件
    confirmFiles () {
      // 单文件
      if (this.maxNum === 1) {
        if (this.checkedFiles.length > 0) {
          this.$emit('checked-files', { url: this.checkedFiles[0], filed: this.filed })
        }
      } else {
        this.$emit('checked-files', { url: this.checkedFiles, filed: this.filed })
      }
      this.uploadVisible = false
      this.checkedFiles = []
    },
    // 删除文件
    delFile (item, ele) {
      // 单文件删除
      if (this.maxNum === 1) {
        this.$emit('del-select', this.filed, '', 'single')
        this.checkedFiles = []
      } else {
        // 多文件删除
        this.$emit('del-select', this.filed, item, 'arr')
        const checkedIndex = this.checkedFiles.find(checkedElement => checkedElement === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.checkedFiles.splice(checkedIndex, 1)
        ele.target.parentNode.classList.remove('checked')
      }
    },
    // 接收已选择的文件
    receiveFiles () {
      if (this.maxNum === 1 && this.url !== '') {
        this.checkedFiles.push(this.url)
      } else if (this.maxNum > 1 && this.urlArr.length > 0) {
        this.checkedFiles = this.urlArr
      }
    },
    // 判断url字符串的文件后缀
    getType (file) {
      const filename = file
      const index1 = filename.lastIndexOf('.')
      const index2 = filename.length
      const type = filename.substring(index1, index2)
      return type
    }
  },
  watch: {
    uploadVisible (val, oldVal) {
      if (val === true) {
        this.getFileList()
        // 接收已上传文件的值
        this.receiveFiles()
      }
    }
  }
}
</script>

<style scoped>
.upload-area{
  display: inline-block;
}
.selected-pre-item{
  position: relative;
  width: auto;
  display: inline-block;
}
.has-seclected-img{
  width: 100px;
  height: 100px;
  border-radius: 3px;
  cursor: pointer
}
.selected-pre-item .el-icon-close{
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: rgba(0,0,0,0.8);
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}
.add-file{
  width: 90px;
  height: 90px;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.add-file i {
  color: rgba(0,0,0,0.4);
}
.add-file:hover{
  border-color: #409EFF;
  color: #409EFF;
}
.add-file:hover i{
  color: #409EFF!important;
}
.upload-dialog-area /deep/.el-dialog__header{
  border-bottom: 1px solid #e8e8e8;
}
.upload-dialog-area /deep/.el-dialog__body{
  padding: 0;
  background: #f6f6f6;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
.upload-dialog-area /deep/.el-tabs__content {
  overflow: hidden;
  position: relative;
  height: 100%;
  background-color: #FFFFFF;
}
.upload-dialog-area /deep/.el-tabs--left .el-tabs__header.is-left{
  margin-right: 0;
}
.upload-dialog-area /deep/.el-tabs__item.is-active {
  background-color: rgba(255,255,255,1);
}
.upload-dialog-area /deep/.el-tabs__item {
  line-height: 40px;
  padding: 0 10px;
  height: 40px;
}
.file-list{
  padding: 10px;
  height: 380px;
}
.file-list .file-item{
  float: left;
  margin: 10px;
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: relative;
  font-size: 12px;
}
.file-list .file-item .el-image{}
.file-list .file-item /deep/.el-image img{
  border-radius: 3px;
}
.file-pagination{
}
.file-pagination /deep/.el-pagination{
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-pagination /deep/.el-pagination .el-pager{
  height: 28px;
}
.file-pagination /deep/.el-pagination .el-pager li.active{
  background-color: #f9f9f9;
}
.file-pagination /deep/.el-pagination .el-pager li{
  background-color: #f9f9f9;
}
.file-pagination /deep/.el-pagination .btn-next, .file-pagination /deep/.el-pagination .btn-prev{
  background-color: #f9f9f9;
}
.dialog-file-footer{
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dialog-file-footer .el-button{
  margin-left: 12px;
}
.upload-pane{
  padding: 20px;
}
.upload-pane /deep/.el-upload--picture-card{
  width: 90px;
  height: 90px;
  line-height: 98px;
  background-size: cover;
  background-position: 50% 50%;
}
.upload-pane /deep/.el-upload-list--picture-card .el-upload-list__item{
  width: 90px;
  height: 90px;
  line-height: 98px;
  background-size: cover;
  background-position: 50% 50%;
}
.upload-pane /deep/.el-upload-list--picture-card .el-progress{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
}
.upload-pane /deep/.el-upload-list--picture-card .el-progress .el-progress-circle{
  width: 70px!important;
  height: 70px!important;
}
.upload-pane-tips{
  padding: 20px 0;
  font-size: 12px;
  color: #999;
}
.dialog-upload-footer{
  position: absolute;
  bottom: 28px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dialog-upload-footer .el-button{
  margin-left: 12px;
}
.selected{
  display: none;
}
.file-item.checked .selected {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #409eff;
  border-radius: 3px;
  text-align: right;
  display: block;
}
.file-item.checked .selected .icon{
  background: #409eff;
  text-align: center;
  height:24px;
  width:24px;
  line-height:24px;
  display: inline-block;
  font-size:16px;
  color: #fff;
  border-radius: 0 0 0 3px;
  position: absolute;
  right: 0;
  top: 0;
}
.selected-pre-list{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.selected-pre-list .selected-pre-item{
  margin: 0px 16px 16px 0;
}
.selected-pre-list .add-file{
  margin: 0px 16px 36px 0;
}
</style>
