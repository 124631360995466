<template>
  <div class="setsand-page">
    <!-- 播放器 -->
    <div id="player-content"></div>
    <!-- 渲染画布 -->
    <div id="render-canvas"></div>
    <!-- 沙盘设置窗口 -->
    <div class="sand-panel">
      <div class="title">
        电子沙盘
      </div>
      <div class="add-img-btn">
        <btn-upload btnName="选择沙盘图片" url="" filed="''" :max-num="1" @checked-files="imgCheckedFiles"></btn-upload>
      </div>
      <div class="sand-img">
        <el-image :src="this.$store.state.panoramaInfo.sand_img" lazy fit="contain">
          <div slot="error" class="image-slot no-sand-img">
            <p>请上传正方形沙盘图片</p>
            <p>图片大小不超过1M</p>
          </div>
        </el-image>
        <div class="del-icon" v-if="this.$store.state.panoramaInfo.sand_img" @click="delBgimg">
          <i class="el-icon-delete"></i>
        </div>
      </div>
      <div class="added-sand" v-if="sandPreviewShow">
        <div class="add-new-point">
          <el-button type="warning" size="small" @click="addSandPoint">添加沙盘点</el-button>
        </div>
        <div class="sand-point-list">
          <div :class="[ activeChoiceCameraMid === item.tid_camera ? 'active' : '',  'point-item']" v-for="item in pointList" :key="item.id" :index="item.id" @click="changeSandItem(item)">
            <div class="point-title">{{item.title}}</div>
            <div class="point-del">
              <el-button size="small" type="danger" icon="el-icon-delete" circle @click="delSanPoint(item.id)"></el-button>
            </div>
          </div>
        </div>
        <div class="submit-sand">
          <el-button type="primary" size="mini" @click="saveAllPoint">保存所有点</el-button>
        </div>
      </div>
    </div>
    <!-- 沙盘预览窗口 -->
    <div class="sand-preview" ref="sand" id="sand" v-if="sandPreviewShow">
      <div class="preview-img">
        <el-image id="sand-img" :src="this.$store.state.panoramaInfo.sand_img" lazy fit="contain"></el-image>
        <div class="knob" v-for="(item, index) in pointList" :key="item.id" draggable="true" :style="'position: absolute; left:' + item.positionX + 'px; top:' + item.positionY + 'px; transform: rotate(' + item.outerRotate + 'deg);'">
          <div class="knob-icon" ref="knob" :style="'transform: rotate(' + item.innerRotate + 'deg);'" v-if="activeChoiceCameraMid === item.tid_camera">
            <img :src="require('../../../../../assets/img/konb.png')"/>
            <div class="center" @mousedown="selectKonb($event, index)"></div>
            <div class="point" @mousedown="rotateKonb($event, index)"></div>
          </div>
          <el-tooltip class="item" effect="dark" :content="item.title" placement="top" v-else>
            <div :key="item.id" class="blue-point" ></div>
          </el-tooltip>
        </div>
      </div>
    </div>
    <!-- 添加沙盘热点弹窗 -->
    <el-dialog title="选择镜头" :visible.sync="addSandPointVisible" width="50%" center>
      <div class="no-add-camera-list">
        <div :class="[ activeChoiceCameraMid === item.m_id ? 'active' : '',  'camera-item']" v-for="item in noAddedCameraList" :key="item.id" :index="item.id" @click="selectCamera(item.m_id)">
          <el-image :src="item.img" lazy fit="contain"></el-image>
          <div class="title">{{item.title}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addSandPointVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmCamera">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import BtnUpload from '@/components/common/BtnUpload'

export default {
  name: 'SetSand',
  components: {
    BtnUpload
  },
  props: {
    cameraItem: Object
  },
  data () {
    return {
      // 场景
      Scene: null,
      // 相机
      Camera: null,
      // 相机参数
      CameraParams: {
        fov: 75,
        near: 0.1,
        far: 1000
      },
      // 控制器
      OrbitControls: null,
      // 渲染器
      Renderer: null,
      // 渲染画布
      renderCanvasDom: null,
      // 视频播放器DOM
      playerDom: null,
      // 外部球体
      OutBall: {
        // 球体
        Geometry: null,
        // 贴图
        Texture: null,
        // 材质
        Material: null,
        // 合成材质的球体
        Mesh: null
      },
      // 播放器
      Player: null,
      sandPreviewShow: false,
      activeChoiceCameraMid: '',
      // 当前激活的沙盘点
      activePointInfo: {
        tid_panorama: '',
        tid_scene: '',
        tid_camera: '',
        positionX: 0,
        positionY: 0,
        outerRotate: 0,
        innerRotate: 0,
        title: ''
      },
      pointList: [],
      noAddedCameraList: [],
      addSandPointVisible: false
    }
  },
  created () {
    if (this.$store.state.panoramaInfo.sand_img) {
      this.sandPreviewShow = true
    }
    // 获取项目下的所有已添加沙盘热点
    this.getSandList()
  },
  methods: {
    // 根据传递的场景信息覆盖默认的材质 和 相机焦点
    dataCover (cameraItem) {
      console.log(cameraItem)
      // 1.1 根据url判断场景类型(视频，图片，模型)
      const urlType = cameraItem.url.substring(cameraItem.url.lastIndexOf('.') + 1)
      if (urlType === 'jpg' || urlType === 'png') {
        // 1.2 图片类型
        const textureLoaderModel = new this.THREE.TextureLoader()
        textureLoaderModel.setCrossOrigin('Anonymous')
        this.OutBall.Texture = textureLoaderModel.load(cameraItem.url, (texture) => {
          this.OutBall.Material.map = texture
          this.OutBall.Material.map.needsUpdate = true
        })
      } else if (urlType === 'm3u8' || urlType === 'flv' || urlType === 'mp4') {
        // 1.3.1 先初始化播放器并播放视频
        this.playerInit(cameraItem)
        // 1.3.2 视频类型
        this.OutBall.Texture = new this.THREE.VideoTexture(this.playerDom)
        this.OutBall.Texture.image.crossOrigin = ''
        this.OutBall.Material.map = this.OutBall.Texture
        this.OutBall.Material.map.needsUpdate = true
      }
      // 设置相机焦点
      this.Camera.position = new this.THREE.Vector3(cameraItem.visionX, cameraItem.visionY, cameraItem.visionZ)
      this.Camera.updateProjectionMatrix()
      // 设置相机或控制器的旋转弧度
      this.OrbitControls.update()
    },
    threeInit () {
      // 渲染画布
      this.renderCanvasDom = document.getElementById('render-canvas')
      // 创建场景
      this.Scene = new this.THREE.Scene()
      // 创建相机
      this.Camera = new this.THREE.PerspectiveCamera(this.CameraParams.fov, this.renderCanvasDom.clientWidth / this.renderCanvasDom.clientHeight, this.CameraParams.near, this.CameraParams.far)
      // 1.创建外部球体和材质
      this.OutBall.Geometry = new this.THREE.SphereGeometry(200, 60, 60)
      // 1.1 缩放球体
      this.OutBall.Geometry.scale(-1, 1, 1)
      // 1.2 外部球体材质
      const textureLoaderModel = new this.THREE.TextureLoader()
      textureLoaderModel.setCrossOrigin('Anonymous')
      this.OutBall.Texture = textureLoaderModel.load(require('../../../../../assets/img/Nosignal.jpg'))
      this.OutBall.Material = new this.THREE.MeshBasicMaterial({ map: this.OutBall.Texture })
      // 1.3 组合材质和球体，添加进入场景
      this.OutBall.Mesh = new this.THREE.Mesh(this.OutBall.Geometry, this.OutBall.Material)
      this.OutBall.Mesh.name = '外部球体'
      this.Scene.add(this.OutBall.Mesh)
      // 2.创建热点层球体和材质

      // 3.处理页面自适应缩放和事件监听
      // 3.1添加窗口大小变化函数
      window.addEventListener('resize', this.onWindowResize)
      // 3.2 添加鼠标滚轮监听事件
      this.renderCanvasDom.addEventListener('mousewheel', this.onMouseWheel, false)
      // 创建渲染器 并渲染画面到画布
      this.Renderer = new this.THREE.WebGLRenderer()
      this.Renderer.setPixelRatio(window.devicePixelRatio)
      this.Renderer.setSize(this.renderCanvasDom.clientWidth, this.renderCanvasDom.clientHeight)
      this.renderCanvasDom.appendChild(this.Renderer.domElement)

      // 4 添加控制器
      this.addOrbitControls()

      // 调用动画渲染
      this.animate()
    },
    // 播放器初始化
    playerInit (cameraItem) {
      // 清空视频播放器的dom
      document.getElementById('player-content').innerHTML = ''
      // 1.1 需要判断链接是否为直播链接
      const urlType = cameraItem.url.substring(cameraItem.url.lastIndexOf('.') + 1)
      this.Player = new this.TcPlayer('player-content', {
        [urlType]: cameraItem.url, // 请替换成实际可用的播放地址
        autoplay: true, // iOS 下 safari 浏览器是不开放这个能力的
        live: urlType !== 'mp4',
        wording: {
          2032: '请求视频失败，请检查网络',
          2048: '请求m3u8文件失败，可能是网络错误或者跨域问题'
        }
      })
      // 获取视频源并播放视频
      const videoDom = document.getElementById('player-content')
      this.playerDom = videoDom.getElementsByTagName('video')[0]
      this.playerDom.crossOrigin = ''
      this.Player.play()
    },
    // 创建控制器
    addOrbitControls () {
      // 创建控制器
      this.OrbitControls = new OrbitControls(this.Camera, this.Renderer.domElement)
      this.OrbitControls.listenToKeyEvents(this.renderCanvasDom)
      // 创建监听
      this.OrbitControls.addEventListener('change', this.controlsChange)
      // 设置镜头阻尼
      this.OrbitControls.enableDamping = true
      this.OrbitControls.dampingFactor = 0.05
      // 禁止摄像机平移
      this.OrbitControls.enablePan = false
      this.OrbitControls.screenSpacePanning = true
      this.OrbitControls.enableZoom = false
      this.OrbitControls.minDistance = 10
      this.OrbitControls.maxDistance = 200
    },
    // 监听窗口大小变化
    onWindowResize () {
      this.Camera.aspect = this.renderCanvasDom.clientWidth / this.renderCanvasDom.clientHeight
      this.Camera.updateProjectionMatrix()
      this.Renderer.setSize(this.renderCanvasDom.clientWidth, this.renderCanvasDom.clientHeight)
    },
    // 监听鼠标滚轮事件
    onMouseWheel (event) {
      event.stopPropagation()
      if (event.wheelDelta) { // 判断浏览器IE，谷歌滑轮事件
        if (event.wheelDelta > 0) { // 当滑轮向上滚动时
          this.CameraParams.fov -= (this.CameraParams.near < this.CameraParams.fov ? 1 : 0)
        }
        if (event.wheelDelta < 0) { // 当滑轮向下滚动时
          this.CameraParams.fov += (this.CameraParams.fov < this.CameraParams.far ? 1 : 0)
        }
      } else if (event.detail) { // Firefox滑轮事件
        if (event.detail > 0) { // 当滑轮向上滚动时
          this.CameraParams.fov -= 1
        }
        if (event.detail < 0) { // 当滑轮向下滚动时
          this.CameraParams.fov += 1
        }
      }
      // 改变fov值，并更新场景的渲染
      this.Camera.fov = this.CameraParams.fov
      this.Camera.updateProjectionMatrix()
      this.OrbitControls.update()
    },
    // 渲染动画
    animate () {
      requestAnimationFrame(this.animate)
      this.Renderer.render(this.Scene, this.Camera)
    },
    // 业务逻辑
    // 获取项目信息
    async getProjectInfo () {
      const { data: res } = await this.$http.get('/panorama-project', { params: { mid: this.$route.params.project_id } })
      if (res.status === 200) {
        this.$store.commit('updatePanoramaAll', res.data.projectInfo)
        this.$store.commit('updateSceneList', res.data.sceneList)
        this.$store.commit('updateCameraList', res.data.cameraList)
        this.$store.commit('updateHotsList', res.data.hotsList)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取项目沙盘点列表
    async getSandList () {
      if (this.cameraItem) {
        const { data: res } = await this.$http.get('/sand-point-list', { params: { panorama_id: this.cameraItem.tid_panorama } })
        if (res.status === 200) {
          this.pointList = res.data
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    // 点击了添加沙盘热点按钮
    async addSandPoint () {
      const { data: res } = await this.$http.get('/sand-no-added-camera', { params: { panorama_id: this.cameraItem.tid_panorama } })
      if (res.status === 200) {
        this.noAddedCameraList = res.data
        this.addSandPointVisible = true
      } else {
        this.$message.error(res.msg)
      }
    },
    // 重新选择缩略图
    imgCheckedFiles (data) {
      this.$store.commit('updatePanoramaItem', { name: 'sand_img', val: data.url })
      this.sandPreviewShow = true
    },
    // 删除沙盘图
    delBgimg () {
      this.$confirm('此操作将删除沙盘下的所有已设置沙盘点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.commit('updatePanoramaItem', { name: 'sand_img', val: '' })
        this.sandPreviewShow = false
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 当控制器改变时
    controlsChange () {
      // 获取当前相机的旋转角度
      const cameraRadian = this.OrbitControls.getAzimuthalAngle()
      // 相机弧度转角度
      let cameraAngel = -(cameraRadian * (180 / Math.PI))
      if (cameraAngel < 0) {
        cameraAngel = 360 + cameraAngel
      }
      const activeCameraIndex = this.pointList.findIndex(item => {
        return item.tid_camera === this.activeChoiceCameraMid
      })
      if (this.pointList[activeCameraIndex]) {
        this.pointList[activeCameraIndex].outerRotate = cameraAngel
      }
    },
    // 拖拽
    selectKonb (e, index) {
      e.preventDefault && e.preventDefault()
      const odiv = e.target.parentNode.parentNode // 获取目标元素
      // 获取画布元素的宽高
      const dragDom = e.target.parentNode.parentNode.parentNode
      const domW = dragDom.offsetWidth
      const dowH = dragDom.offsetHeight
      // 获取拖动图标的宽高
      const midKonbDomW = odiv.offsetWidth
      const midKonbDomH = odiv.offsetHeight
      // 算出鼠标相对元素的位置
      const disX = e.clientX - odiv.offsetLeft
      const disY = e.clientY - odiv.offsetTop
      // 绑定元素位置到positionX和positionY上面
      window.onmousemove = (e) => {
        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = 0
        let top = 0
        if ((e.clientX - disX) <= 0) {
          left = 0
        } else if ((e.clientX - disX) >= (domW - midKonbDomW)) {
          left = domW - midKonbDomW
        } else {
          left = e.clientX - disX
        }
        if ((e.clientY - disY) <= 0) {
          top = 0
        } else if ((e.clientY - disY) >= (dowH - midKonbDomH)) {
          top = dowH - midKonbDomH
        } else {
          top = e.clientY - disY
        }
        this.pointList[index].positionX = left
        this.pointList[index].positionY = top
        window.onmouseup = (e) => {
          window.onmousemove = null
          window.onmouseup = null
        }
      }
    },
    // 旋转
    rotateKonb (e, index) {
      e.preventDefault && e.preventDefault()
      // 获取目标元素
      const odiv = e.target.parentNode.parentNode // 获取目标元素
      const outerDiv = e.target.parentNode.parentNode.parentNode
      // 获取拖动图标的宽高
      const midKonbDomW = odiv.offsetWidth
      const midKonbDomH = odiv.offsetHeight
      // 获取目标元素中心点
      const desdence = odiv.getBoundingClientRect()
      const centerPoint = {
        x: desdence.left + (midKonbDomW / 2),
        y: desdence.top + (midKonbDomH / 2)
      }
      outerDiv.onmousemove = (event) => {
        let angle
        angle = (-Math.atan2(centerPoint.x - event.clientX, centerPoint.y - event.clientY) / Math.PI * 180) - this.pointList[index].outerRotate
        if (angle < 0) {
          angle = 360 + angle
        }
        // 获取该点开始时的旋转角度
        this.pointList[index].innerRotate = angle
        outerDiv.onmouseup = (e) => {
          outerDiv.onmousemove = null
          outerDiv.onmouseup = null
        }
        outerDiv.onmouseleave = (e) => {
          outerDiv.onmousemove = null
          outerDiv.onmouseup = null
        }
      }
    },
    // 工具方法
    // 选择镜头
    // 切换沙盘热点
    changeSandItem (item) {
      this.activeChoiceCameraMid = item.tid_camera
      this.$bus.$emit('bottomSceneChangeActive', { activeScene: item.tid_scene, activeCamera: item.tid_camera })
    },
    // 保存所有点
    async saveAllPoint () {
      const { data: res } = await this.$http.put('/set-sand-point', { pointList: this.pointList })
      if (res.status === 200) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    selectCamera (mid) {
      this.activeChoiceCameraMid = mid
    },
    // 删除热点
    async delSanPoint (id) {
      const { data: res } = await this.$http.delete('/set-sand-point', { params: { id: id } })
      if (res.status === 200) {
        const delIndex = this.pointList.findIndex(item => {
          return item.id === id
        })
        this.pointList.splice(delIndex, 1)
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 确认镜头
    async confirmCamera () {
      const activeMid = this.noAddedCameraList.findIndex(item => {
        return item.m_id === this.activeChoiceCameraMid
      })
      this.activePointInfo.tid_panorama = this.noAddedCameraList[activeMid].tid_panorama
      this.activePointInfo.tid_scene = this.noAddedCameraList[activeMid].tid_scene
      this.activePointInfo.tid_camera = this.activeChoiceCameraMid
      this.activePointInfo.positionX = 0
      this.activePointInfo.positionY = 0
      this.activePointInfo.outerRotate = 0
      this.activePointInfo.innerRotate = 0
      this.activePointInfo.title = this.noAddedCameraList[activeMid].title
      const { data: res } = await this.$http.post('/set-sand-point', this.activePointInfo)
      if (res.status === 200) {
        this.$bus.$emit('bottomSceneChangeActive', { activeScene: this.activePointInfo.tid_scene, activeCamera: this.activePointInfo.tid_camera })
        this.pointList.push(res.data)
        this.addSandPointVisible = false
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  mounted () {
    this.threeInit()
  },
  // 监听父组件cameraItem值是否改变
  watch: {
    cameraItem: { // 深度监听，可监听到对象、数组的变化
      handler (newV, oldV) {
        this.dataCover(newV)
        this.getSandList()
        this.activeChoiceCameraMid = newV.m_id
      },
      deep: true
    },
    '$store.state.panoramaInfo.sand_img' (newval, olval) {
      if (newval !== '') {
        this.sandPreviewShow = true
      } else {
        this.sandPreviewShow = false
      }
    }
  }
}
</script>

<style scoped>
.setsand-page{
  width: 100%;
  height: 100%;
}
#render-canvas{
  width: 100%;
  height: 100%;
}
.sand-panel{
  position: absolute;
  right: 0;
  top: 5px;
  width: 220px;
  background-color: #1c2438;
  height: calc(100vh - 240px);
  padding: 10px;
  z-index: 100;
}
.sand-panel .title{
  background-color: #FFFFFF;
  background-image: linear-gradient(to right, #FFFFFF , #1c2438);
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-weight: bold;
  font-size: 14px;
}
.add-img-btn{
  padding: 10px 0;
  text-align: right;
}
.sand-img{
  position: relative;
}
.sand-img .del-icon{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0,0,0,0.6);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #FFFFFF;
  cursor: pointer;
}
.sand-img .del-icon:hover{
  background-color: #F56C6C;
}
.sand-img .el-image{
  width: 200px;
  height: 200px;
  background-color: #f5f7fa;
}
/deep/ .no-sand-img{
  width: 200px;
  height: 200px;
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/deep/.no-sand-img p{
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.4;
}
.sand-preview{
  position: absolute;
  right: 225px;
  top: 5px;
  width: 250px;
  height: auto;
  background-color: #1c2438;
  padding: 10px;
  z-index: 100;
}
.sand-preview .preview-img{
  width: 100%;
  height: 100%;
}
.sand-preview .preview-img .el-image{
  width: 100%;
  height: 100%;
}
.added-sand{
  height: calc(100% - 300px);
  position: relative;
}
.added-sand .add-new-point{
  text-align: center;
  margin-top: 10px;
}
.sand-point-list{}
.sand-point-list .point-item{
  background-color: rgba(255,255,255,0.1);
  padding: 10px;
  margin: 10px 0;
  position: relative;
  border-radius: 4px 30px 30px 4px;
  height: 36px;
  width: 100%;
  cursor: pointer;
}
.sand-point-list .point-item .point-title{
  color: #FFFFFF;
  font-size: 13px;
  opacity: 0.5;
}
.sand-point-list .point-item .point-del{
  position: absolute;
  right: 2px;
  top: 2px;
  opacity: 0.2;
}
.sand-point-list .point-item:hover .point-title{
  opacity: 1;
}
.sand-point-list .point-item:hover .point-del{
  opacity: 1;
}
.sand-point-list .point-item.active{
  background-color: rgba(255,255,255,1);
}
.sand-point-list .point-item.active .point-title{
  color: #1c2438;
  opacity: 1;
}
.sand-point-list .point-item.active .point-del{
  opacity: 1;
}
.submit-sand{
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.submit-sand .el-button{
  width: 100%;
}
/deep/.hot-point{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}
.knob{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  user-select: none;
}
.knob img{
  width: 100%;
  height: 100%;
}
.knob .center{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  cursor: grab;
}
.knob .point{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 14px;
  height: 14px;
  cursor: ew-resize;
  border-radius: 50%;
}
.knob-icon{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.blue-point{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(40, 110, 250);
  border: #fff 2px solid;
}
.no-add-camera-list{
  display: flex;
  flex-wrap: wrap;
}
.no-add-camera-list .camera-item{
  width: 120px;
  margin: 10px;
  background-color: rgba(0,0,0,0.1);
  cursor: pointer;
}
.no-add-camera-list .camera-item .el-image{
  width: 120px;
  height: 120px;
}
.no-add-camera-list .camera-item .title{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: rgba(0,0,0,0.4);
  color: #FFFFFF;
  text-align: center;
  padding: 6px;
  font-size: 13px;
}
.no-add-camera-list .active.camera-item .title{
  background-color: #E6A23C;
}
</style>
